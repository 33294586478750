import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import PauseIcon from "@mui/icons-material/Pause";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from "@mui/material/Tooltip";
import ProgressBar from "./ProgressBar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import MyResponsiveLine from './lineChart';
import MyResponsivePie from './PieChart';
import Footer from "../widgets/Footer";
import { generateUserLogs } from "../../services/Endpoints";
dayjs.extend(utc);

const getOneMonthAgo = () => {
  const currentDate = dayjs();
  const oneMonthAgo = currentDate.subtract(1, "month");
  return oneMonthAgo;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const processLineChartData = (tableData, startDate, endDate) => {


  const filteredData = tableData.filter((row) => {
    const rowDate = formatDate(row.LastAccessedDate);
    return rowDate >= formatDate(startDate) && rowDate <= formatDate(endDate.add(1, 'day'));
  });
  console.log('hello', tableData)
  const groupedData = filteredData.reduce((acc, row) => {
    const role = row.Role;
    const date = formatDate(row.LastAccessedDate);
    const dateString = formatDate(date);

    if (!acc[role]) {
      acc[role] = {};
    }

    if (!acc[role][dateString]) {
      acc[role][dateString] = 0;
    }

    acc[role][dateString] += row.LoginCount;
    return acc;
  }, {});

  const lineChartData = Object.entries(groupedData).map(([role, dateCounts]) => {
    const dataPoints = [];

    let currentDate = startDate;
    while (currentDate <= endDate.add(1, "day")) {
      const dateString = formatDate(currentDate);
      dataPoints.push({
        x: dateString,
        y: dateCounts[dateString] || 0,
      });
      currentDate = currentDate.add(1, "day");
    }

    return {
      id: role,
      data: dataPoints,
    };
  });

  return lineChartData;
};

const processPieChartData = (tableData, startDate, endDate) => {
  const filteredData = tableData.filter((row) => {
    const rowDate = formatDate(row.LastAccessedDate);
    return rowDate >= formatDate(startDate) && rowDate <= formatDate(endDate.add(1, 'day'));
  });

  const groupedData = filteredData.reduce((acc, row) => {
    const role = row.Role;
    const count = row.LoginCount;
    if (acc[role]) {
      acc[role] += count;
    } else {
      acc[role] = count;
    }
    return acc;
  }, {});

  return Object.entries(groupedData).map(([role, count]) => ({
    id: role,
    label: role,
    value: count,
    color: "hsl(" + Math.random() * 360 + ", 70%, 50%)",
  }));
};

const UserLogs = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(getOneMonthAgo());
  const [endDate, setEndDate] = useState(dayjs());
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalLoginCount, setTotalLoginCount] = useState(0);
  const [pieChartData, setpiechartData] = useState([]);
  const [lineChartData, setlinechartData] = useState([]);

  useEffect(() => {
    getUserLogs();
  }, []);

  useEffect(() => {
    setFilteredData(data);
    setlinechartData(processLineChartData(data, startDate, endDate));
    setpiechartData(processPieChartData(data, startDate, endDate));
  }, [data]);

  const handleChangePage = (event, newPage) => {
    console.log("event222", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("event22", event.target.value);

    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const convertToCST = (val) => {
    const utcTime = new Date(val);
    const cstTime = utcTime.toLocaleString("en-US", {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return cstTime;
  };

  function formatDateToDaysOrMonthsAgo(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    const differenceInMilliseconds = currentDate - inputDate;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    const differenceInMonths = Math.floor(differenceInDays / 30);

    if (differenceInMonths >= 2) {
      return {
        date: dateString,
        timeDiff: differenceInMonths + " months ago",
        type: "inactive",
      };
    } else if (differenceInMonths === 1) {
      return { date: dateString, timeDiff: "1 month ago", type: "inactive" };
    } else {
      return {
        date: dateString,
        timeDiff: differenceInDays + " days ago",
        type: differenceInDays > 15 ? "active" : "recently active",
      };
    }
  }

  const getUserLogs = async () => {
    const formattedStartDate = dayjs(startDate).hour(0).minute(0).second(0).utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
    const formattedEndDate = dayjs(endDate).hour(23).minute(59).second(59).utc().format("YYYY-MM-DDTHH:mm:ss.SSS");

    try {
      setIsLoading(true);
      generateUserLogs(formattedStartDate, formattedEndDate, (value) => {
        let sum = 0;
        const tempStore = value.map((item) => {
          sum += item.LoginCount;
          item = {
            FirstName: item.FirstName,
            LastName: item.LastName,
            FullName: item.FirstName + " " + item.LastName,
            OneHealthId: item.OneHealthId,
            UserEmail: item.UserEmail,
            APCId: item.APCId,
            Role: item.Role,
            LoadDate: item.LoadDate,
            loadDateDifference: formatDateToDaysOrMonthsAgo(item.LoadDate),
            LoginCount: item.LoginCount,
            TotalTimeSpent: item.TotalTimeSpent,
            LastAccessedDate: item.LastAccessedDate
              ? convertToCST(item.LastAccessedDate, "date")
              : null,
          };
          return item;
        });
        setlinechartData(processLineChartData(filteredData, startDate, endDate));
        setpiechartData(processPieChartData(filteredData, startDate, endDate));
        console.log("dummyData", tempStore, sum);
        setTotalLoginCount(sum);
        setData(tempStore);
        setIsLoading(false);
      });

    } catch (e) {
      console.log(e);
    }
    setFilteredData(data);
  };

  const columns1 = [
    {
      label: "Name",
      id: "FullName",
      key: "FullName",
      format: (fullname) => {
        return (
          <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{fullname}</div>
        );
      },
    },

    {
      label: "OHID",
      id: "OneHealthId",
      key: "OneHealthId",
      format: (onehealthid) => {
        return (
          <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{onehealthid}</div>
        );
      },
    },

    {
      label: "User Role",
      id: "Role",
      key: "Role",
      format: (role) => {
        const roleColor = {
          "Demo User": "#155C8E",
          "Devops Admin": "#15A796",
          "Quality Coordinator": "#C72887",
          "Client Manager": "#8061BC",
        };

        return (
          <div style={{ display: "flex", alignItems: "center", fontFamily: "Optum Sans, Helvetica, Arial" }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: roleColor[role],
                marginRight: "5px",
              }}
            ></div>
            <span>{role}</span>
          </div>
        );
      },
    },

    {
      label: "Load Date",
      id: "loadDateDifference",
      key: "loadDateDifference",
      format: (loadDateDifference) => (
        <Tooltip title={loadDateDifference.date} placement="top" arrow>
          <div
            style={{
              padding: "8px",
              width: "70%",
              borderRadius: "10px",
              fontFamily: "Optum Sans, Helvetica, Arial"
            }}
          >
            <span>
              {loadDateDifference.timeDiff}
            </span>
          </div>
        </Tooltip>
      ),
      minWidth: "80px",
    },

    {
      label: "Login Count",
      id: "LoginCount",
      key: "LoginCount",
      //
      format: (LoginCount) => (
        <div
          style={{
            padding: "4px",
            fontFamily: "Optum Sans, Helvetica, Arial",
            width: "80%",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontFamily: "Optum Sans, Helvetica, Arial",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
            }}
          >
            {" "}
            LoginCount,{LoginCount}
          </div>
          <div
            style={{
              textAlign: "center",
              fontFamily: "Optum Sans, Helvetica, Arial",
              justifyContent: "center",
              alignItems: "center",
              // width: "80%",
              margin: 0,
            }}
          >
            <ProgressBar
              key={0}
              bgcolor="#68AD60"
              completed={Math.round((LoginCount * 100) / totalLoginCount)}
            />
          </div>
        </div>
      ),
      minWidth: "80px",
    },

    {
      label: "Total Time Spent",
      id: "TotalTimeSpent",
      key: "TotalTimeSpent",
      minWidth: "80px",
      format: (totaltime) => {
        return (
          <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{totaltime}</div>
        );
      },
    },

    {
      label: "Last Accessed Date",
      id: "LastAccessedDate",
      key: "LastAccessedDate",
      minWidth: "80px",
      format: (lastaccessed) => {
        return (
          <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>{lastaccessed}</div>
        );
      },
    },
  ];

  const handleClose = (reason) => {
    // console.log("reason", event, reason);

    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setIsPaused(false);
  };
  const action = (
    <React.Fragment>
      <Button color="warning" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setIsPaused((currentValue) => !currentValue);
        }}
      >
        {isPaused ? <PauseIcon /> : <PlayArrowIcon fontSize="small" />}
      </IconButton>
    </React.Fragment>
  );

  const dateRange = endDate.diff(startDate, "days");
  const customAxisBottom = {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "date",
    legendOffset: 36,
    legendPosition: "middle",
    tickValues:
      dateRange > 7
        ? [
          formatDate(startDate),
          formatDate(startDate.add(Math.floor(dateRange / 2), "day")),
          formatDate(endDate),
        ]
        : undefined,
  };

  const noDataStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "20px",
    color: "lightgrey",
    textAlign: "center",
  };

  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      <div style={{ marginTop: "30px", marginLeft: "30px", marginRight: '70px', fontFamily: 'Optum Sans, Helvetica, Arial' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            sx={{
              marginRight: "20px",
              marginBottom: "20px",
              '& .MuiInputLabel-root': {
                fontFamily: 'Optum Sans, Helvetica, Arial'
              }
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            minDate={startDate}
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            sx={{
              '& .MuiInputLabel-root': {
                fontFamily: 'Optum Sans, Helvetica, Arial'
              }
            }}
          />
        </LocalizationProvider>
        <TextField
          id="outlined-basic"
          label="Find"
          variant="outlined"
          onChange={(event) => setSearchTerm(event.target.value)}
          style={{ marginLeft: "20px", marginBottom: "20px" }}
          sx={{
            '& .MuiInputLabel-root': {
              fontFamily: 'Optum Sans, Helvetica, Arial'
            }
          }}
        />
        <div style={{ display: "inline-flex", alignItems: "center", fontFamily: 'Optum Sans, Helvetica, Arial' }}>
          <button
            onMouseDown={() => setIsButtonClicked(true)}
            onMouseUp={() => setIsButtonClicked(false)}
            onClick={getUserLogs}
            style={{
              backgroundColor: "#002677",
              fontFamily: 'Optum Sans, Helvetica, Arial',
              color: "white",
              borderRadius: "5px",
              padding: "10px 20px",
              border: "none",
              marginLeft: "40px",
              marginTop: "9px",
              cursor: "pointer",
              transform: isButtonClicked ? "scale(0.95)" : "scale(1)",
              transition: "transform 0.1s ease",
            }}
          >
            Search
          </button>
          {isLoading && (
            <div style={{ marginLeft: "10px", marginTop: "10px" }}>
              <CircularProgress size={20} />
            </div>
          )}
        </div>

        <div style={{ display: "flex", marginBottom: "10px", textAlign: "center", height: "350px", fontFamily: 'Optum Sans, Helvetica, Arial' }}>
          <div style={{
            border: "2px solid #e6e6e6",
            fontFamily: 'Optum Sans, Helvetica, Arial',
            position: "relative",
            width: "72.5%",
            paddingTop: "10px",
          }}>
            <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>
              <b> Login Count Daily Report </b>
            </div>
            {lineChartData.length === 0 && (
              <div style={noDataStyle}>No Data to Display</div>
            )}
            <MyResponsiveLine data={lineChartData} axisBottom={customAxisBottom} />
          </div>
          <div
            style={{
              marginLeft: "0.5%",
              paddingTop: "10px",
              border: "2px solid #e6e6e6",
              position: "relative",
              width: "27%",
            }}
          >
            <div style={{ fontFamily: "Optum Sans, Helvetica, Arial" }}>
              <b> Login Count </b>
            </div>
            {pieChartData.length === 0 && (
              <div style={noDataStyle}>No Data to Display</div>
            )}
            <MyResponsivePie data={pieChartData} />
          </div>
        </div>
        <div style={{ zIndex: 0, position: "relative" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns1.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          width: 360,
                          borderRight: "1px solid #CBCCCD",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#002677",
                          minWidth: column.minWidth,
                          fontFamily: 'Optum Sans, Helvetica, Arial'
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .filter((row) => {
                      if (searchTerm === "") {
                        return true;
                      }

                      let lowerCaseSearchTerm = searchTerm.toLowerCase();

                      return (
                        row.FullName.toLowerCase().includes(
                          lowerCaseSearchTerm
                        ) ||
                        row.OneHealthId.toLowerCase().includes(
                          lowerCaseSearchTerm
                        ) ||
                        row.Role.toLowerCase().includes(lowerCaseSearchTerm)
                      );
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns1.map((column) => {
                            const value = row[column.id];
                            // console.log("value", value, column.id);

                            return (
                              <TableCell
                                style={{
                                  width: 360,
                                  borderRight: "1px solid #CBCCCD",
                                }}
                                key={column.id}
                              >
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "All", value: data.length },
              ]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      <div style={{ marginTop: '60px', marginLeft: '40px' }}>
        <Footer />
      </div>
    </div>
  );
};

export default UserLogs;